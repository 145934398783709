@import "../../../styles/mixins/textStyle.mixin";
@import "../../../styles/variables/colors";
@import "../../../styles/mixins/objectFit.mixin.scss";

.sections {
  display: flex;
  flex-wrap: wrap;
}

.section {
  flex-basis: 50%;
  width: 50%;
  padding: 6% 7%;
  line-height: 2.8rem;
  background-color: $blue;
  @include textStyle(white, 1.6rem, normal, normal, normal);
}

.section-image {
  flex-basis: 50%;
  width: 50%;
}

.image {
  @include object-fit(cover);
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  display: flex;
}

.tabs {
  margin-top: 10rem;
  padding: 4% 6%;
}

.tabContent {
  max-width: 90rem;
  width: 100%;
  margin: auto;
  margin-top: 10rem;
  @include textStyle($grey_text, 2rem, 500);
}

@media screen and (max-width: 1024px) {
  .section {
    flex-basis: 100%;
    width: 100%;
  }

  .section-image {
    flex-basis: 100%;
    width: 100%;
  }
}
