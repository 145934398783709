@import "../../../../styles/mixins/textStyle.mixin";
@import "../../../../styles/variables/colors";

.wrapper {
  margin-bottom: 2rem;
  width: 100%;
  letter-spacing: 0.5px;
}

.title {
  @include textStyle($blue, 2.2rem, 400);
  position: relative;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  line-height: 5rem;
}

.arrow {
  border: solid $blue;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  height: 0px;

  &.up {
    transform: rotate(-135deg);
  }

  &.down {
    transform: rotate(45deg);
  }
}

.container {
  max-height: 0;
  padding: 0 2rem;
  line-height: 4rem;
  overflow: hidden;
  transition: 0.5s ease;
  letter-spacing: 0.5px;

  a {
    display: block;
    color: $blue;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

.active {
  max-height: unset;
  padding: 2rem;

  &::after {
    transform: rotate(270deg);
  }
}

.item {
  @include textStyle($blue, 2rem, 400);
  font-weight: 100;
}
