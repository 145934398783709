.main {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  [class^="Section_section"]:last-child p:last-child img {
    display: block;
    margin: 30px auto 0;
  }
}

.columnedBlock {
  width: 50%;
}

@media screen and (max-width: 1024px) {
  .main {
    flex-direction: column;
  }

  .columnedBlock {
    width: 100%;
  }
}
