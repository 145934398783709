@import "../../styles/variables/colors";
@import "../../styles/mixins/textStyle.mixin";

.main {
  display: flex;
  flex-wrap: wrap;
  & > div [class^="Section"] {
    &:nth-child(3) {
      @media screen and (max-width: 1024px) {
        order: 4;
      }
    }
    &:nth-child(4) {
      @media screen and (max-width: 1024px) {
        order: 3;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .main {
    flex-direction: column;
  }
}
