@import "../../../styles/variables/colors";
@import "../../../styles/mixins/textStyle.mixin";

.main {
  display: flex;
}

@media screen and (max-width: 1024px) {
  .main {
    flex-direction: column;
    > div {
      width: 100%;
    }
  }
}
