@import "../../styles/variables/colors";
@import "../../styles/mixins/textStyle.mixin";
@import "../../styles/mixins/objectFit.mixin.scss";

.wrapper {
  position: relative;
  height: 45rem;
}

.background {
  @include object-fit(cover);
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
  object-position: center;
  z-index: -1;
  transition: all 0.2s ease-in-out;
  opacity: 0;
}

.wrapperImg {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
}

.faded {
  opacity: 1;
}

.wrapper-with-under-title {
  padding-top: 35vh;
  background-size: cover;
}

.title {
  @include textStyle(#fff, 8rem, 700, normal, normal);
  font-family: "UniversLTStd-BoldCn.otf";
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  color: #fff;
  text-shadow: 1px 1px 2px #5d5d5d;
  margin: 0;
  padding: 0 0 0 10%;
  box-sizing: border-box;
  top: 60%;
  transform: translateY(-50%);
  background: none !important;
  position: absolute;
}

.under-title {
  padding: 5% 7%;
  background-color: #f1f2f2;
  color: #5d5d5d;
  max-width: 624px;
  box-sizing: border-box;
  font-size: 2.2rem;
  font-weight: 300;
  font-family: "Univers LT Std";
  span {
    opacity: 0.65;
    max-width: 390px;
    display: block;
    line-height: 1.36;
    margin: 0 auto;
  }
  @media screen and (max-width: 1024px) {
    padding: 12% 7%;
    span {
      max-width: 48rem;
    }
  }
}

@media screen and (max-width: 1450px) {
  .title {
    font-size: 6.5rem;
  }
}

@media screen and (max-width: 1024px) {
  .title {
    padding: 0 0 0 10rem;
    font-size: 5.5rem;
  }
}

@media screen and (max-width: 768px) {
  .title {
    padding-left: 7%;
  }
}

@media screen and (max-width: 640px) {
  .title {
    font-size: 3.5rem;
    padding-left: 7%;
  }

  .wrapper {
    max-height: initial;
    min-height: initial;
    display: flex;
    flex-direction: column;
  }

  .description {
    padding: 4rem;
  }

  .background {
    position: relative;
  }
}

@media screen and (max-width: 500px) {
  .title {
    padding: 6rem 2rem;
    font-size: 3.5rem;
  }
}
