.container {
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 1013;
  padding: 15px;
}

.date {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 14px;
  color: rgba(34, 34, 34, 0.5);
  padding: 15px 0 0 0;
}

.chatbot {
  height: 300px;
  width: 340px;
  background-color: #fff;
  margin-bottom: 7px;
  border-radius: 7px;
  box-shadow: 0px 0px 10px 0px #000;
  position: relative;
  .footer {
    position: absolute;
    bottom: 0px;
    height: 50px;
    background-color: #002c90;
    width: 100%;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #b6b6b6;
    font-weight: 300;
    letter-spacing: 1.1px;
  }
}

.switch_icon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;

  span {
    box-shadow: 0px 0px 4px 0px #000;
    transition: box-shadow 0.3s ease;
    height: 70px;
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #002c90;
    border-radius: 8px;
    &:hover {
      box-shadow: 0px 0px 8px 0px #000;
    }
  }
}

.header {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  background-color: #002c90;
  box-shadow: 0px 7px 20px -13px;
  padding: 0 0.8rem;
  overflow: hidden;
  color: #fff;
  &__bot_credentials {
    display: flex;
    align-items: center;
    span {
      margin-left: 10px;
      letter-spacing: 1.1px;
      font-size: 16px;
      font-weight: 300;
    }
  }
  &__close_button {
    fill: currentColor;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    &:hover {
      fill: rgb(226, 226, 226);
      background-color: rgba(0, 0, 0, 0.35);
    }
  }
}

.conversation {
  padding: 8px;

  .message {
    margin-top: 8px;
    display: flex;
    align-items: flex-end;
    &__body {
      background-color: #ebebeb;
      padding: 0.7rem 0.85rem;
      white-space: pre-wrap;
      word-wrap: break-word;
      border-radius: 20px;
      color: #333;
      font-size: 16px;
    }
    &__body_container {
      display: flex;
      flex-direction: column;
      margin-left: 8px;
    }
    &__body_bot {
      margin: 0 0 5px 8px;
      color: #52626b;
      font-weight: 400;
    }
  }
}
.options_container {
  margin-top: 35px;
  .option {
    color: #002c90;
    border: 1px solid #002c90;
    background-color: rgb(255, 255, 255);
    display: inline-block;
    margin: 3px 4px;
    font-size: 16px;
    padding: 0.7rem 1.2rem;
    border-radius: 20px;
    min-width: 5rem;
    text-align: center;
    transition: all 0.3s ease;
    &:hover {
      color: #fff;
      background-color: #002c90;
      border: 1px solid #002c90;
    }
  }
}
