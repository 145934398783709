@import "../../styles/variables/colors";

.wrapper {
  display: flex;
}

.item {
  width: 22rem;
  height: 13rem;
  background-color: $opacityBlue;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.25s ease;
  cursor: pointer;

  &:hover {
    background-color: $blue;
  }
}

.link {
  font-family: "Univers LT Std";
  font-size: 1.7rem;
  font-weight: 400;
  letter-spacing: 1.1px;
  color: white;
  display: flex;
  justify-content: space-between;
  margin-right: 2.5rem;
}

.arrow {
  border: solid white;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  height: 0px;
  transform: rotate(-45deg);
}

/* Added to account for menu items with longer text */
@media screen and (min-width:1151px) {
  .MainMenu_link__l8l-8 {
    max-width: 120px;
    line-height: 1.5;
  }
}

@media screen and (max-width: 1150px) {
  .wrapper {
    flex-direction: column;
  }
  .item {
    height: 8rem;
  }
  .link {
    width: 9.4rem;
  }
}

@media screen and (max-width: 600px) {
  .item {
    height: 6rem;
  }
}
