@import "./fonts.scss";
@import "./normalize";
@import "./typography";
@import "./gmaps";
@import "./variables/colors";

button,
input,
textarea {
  outline: none;
}

html {
  font-size: 10px;
  width: 100%;
}

body {
  margin: 0;
  min-height: 100vh;
  width: 100%;
  font-family: "Univers LT Std";
}

.app-wrapper {
  width: 100%;
  height: 100%;
}

.container {
  padding: 0 6%;
}

.slick-slider,
.slick-list,
.slick-track {
  height: 100%;
}

.slick-slide > div {
  height: 100%;
}

.fb-page,
.fb-page span,
.fb-page span iframe[style] {
  max-width: 100% !important;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;

  &:before,
  &:after {
    content: "";
    display: table;
  }
}
