@import "../../styles/variables/colors";
@import "../../styles/mixins/textStyle.mixin";
@import "../../styles/mixins/objectFit.mixin.scss";

.container {
  padding: 0 1.5rem;
}

.wrapper {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  z-index: 10;
  height: 510px;
  max-width: 400px;
  width: 100%;
  box-shadow: 18px 18px 15px -8px #ededed;
  flex-direction: column;
  justify-content: space-between;

  .play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.info {
  background: #fff;
  padding: 10%;
}

.read {
  @include textStyle($blue, 1.8rem, 300, normal, normal);
  display: flex;
  align-items: center;

  span {
    margin-right: 1.5rem;
  }
}

.img-wrapper {
  width: 100%;
  height: 300px;
  position: relative;

  img {
    @include object-fit(cover);
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.date {
  @include textStyle($grey, 1.8rem, 300, normal, normal);
  opacity: 0.5;
  margin-bottom: 1.5rem;
}

.title {
  @include textStyle($grey, 1.8rem, 300, normal, normal);
  margin-bottom: 35px;
}

@media screen and (max-width: 550px) {
  .wrapper {
    width: calc(100% - 30px);
  }

  .active-wrapper {
    width: 280px;
  }
}
