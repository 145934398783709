.main {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 1024px) {
  .main {
    flex-direction: column;
  }
}
