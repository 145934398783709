@import "../../styles/variables/colors";
@import "../../styles/mixins/textStyle.mixin";

.title {
  font-size: 3.2rem;
  color: $blue !important;
  margin-bottom: 4.5rem;
  display: block;
}

.titleLink {
  &:hover {
    text-decoration: underline;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.links-column {
  margin-bottom: 7rem;

  div:last-child {
    margin-bottom: 0;
  }
}

.contacts-info {
  opacity: 0.65;
  @include textStyle($grey_text, 1.8rem, 300, normal, normal);
  margin-bottom: 6rem;

  a {
    @include textStyle($grey_text, 1.8rem, 300, normal, normal);
    margin-bottom: 0;
    text-decoration: underline;
    display: inline-block;
    padding-left: 3px;
  }

  p:nth-child(1) {
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 1450px) {
  .title {
    font-size: 2.3rem;
    // margin-bottom: 2.3rem;
  }

  .links-column {
    a {
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }

  .contacts-info {
    margin-bottom: 4rem;
  }

  .contacts-info p,
  .contacts-info a {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .links-column {
    margin-bottom: 5rem;
  }

  .quick-links-wrapper {
    padding: 15rem 5.5rem;
  }
}

.link-item {
  @include textStyle($blue, 1.8rem, 400, normal, normal);
  margin-bottom: 1.6rem;
  display: flex;
  align-items: center;

  a > i {
    visibility: hidden;
  }

  a:hover i {
    visibility: visible;
  }
}
@media screen and (max-width: 1024px) {
  .link-item {
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 500px) {
  .link-item {
    margin-bottom: 0.8rem;
  }

  .links-column {
    margin-bottom: 3rem;
  }

  .contacts-info {
    margin-bottom: 3rem;
  }
}

/*Height media*/
@media screen and (max-height: 540px) {
  .title {
    margin-bottom: 1.6rem;
  }

  .links-column a {
    margin-bottom: 1rem;
  }

  .contacts-info {
    margin-bottom: 1rem;
  }

  .link-item {
    margin-bottom: 1rem;
  }
}

@media screen and (max-height: 430px) {
  .title {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }

  .links-column {
    margin-bottom: 1.5rem;
  }

  .links-column a {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }

  .contacts-info p,
  .contacts-info a {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
}

@media screen and (max-height: 340px) {
  .links-column {
    margin-bottom: 0.5rem;
  }
}
