$blue: #002c90;
$opacityBlue: rgba(0, 44, 144, 0.6);
$menu_cyan: #12a49f;
$lightgray_background: #f1f2f2;
$darkgray_background: #e7e9ea;
$grey_text: #5d5d5d;
$lightgray_text: rgba(93, 93, 93, 0.65);
$grey_station: rgb(175, 175, 175);
$grey: #5d5d5d;
$pink: #eb16a4d7;
$gradient: rgba(235, 96, 16, 0.943);
