.LB_StoreLocator .row.mainrow {
  padding-inline: 3rem !important;
}

.LB_StoreLocator .row.mainrow > div {
  padding-block: 2rem !important;
  border-bottom: 1px solid #002c90 !important;
  color: #002c90 !important;
}

.LB_StoreLocator .row.mainrow > div .btn-accent {
  background-color: #002c90 !important;
  width: 100% !important;
  color: #fff !important;
  display: block !important;
  text-align: center !important;
  padding-block: 1.5rem !important;
  border-radius: 0.5rem !important;
}

.LB_StoreLocator .row.mainrow > div > div:nth-last-of-type(1) > .row:nth-of-type(1) {
  display: flex !important;
  justify-content: space-between !important;
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}

.LB_StoreLocator .row.mainrow > div > div:nth-last-of-type(1) > .row:nth-of-type(1) > div {
  display: flex !important;
  padding: 0 !important;
  flex: 1 !important;
  justify-content: end !important;
}

.LB_StoreLocator .SL_MapInfoWindow div.row {
  border: none !important;
  padding-block: 0 !important;
}

.LB_StoreLocator .locationHeading > div {
  font-size: 1.3rem !important;
  line-height: 1.6rem !important;
}

.LB_StoreLocator .form {
  padding: 0 !important;
  display: flex !important;
  flex-direction: column !important;
  width: 100% !important;
  position: relative !important;
}

.LB_StoreLocator .AdvanceSearchHeader {
  display: none;
}

.LB_StoreLocator .ShowAdvanceSearchDetails {
  padding-inline: 1rem !important;
  height: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
  top: 55px !important;
  z-index: 2;
  background-color: #002c90 !important;
  padding-block: 1rem !important;
}

.LB_StoreLocator .ShowAdvanceSearchDetails.show {
  height: 100px !important;
}

.LB_StoreLocator .form-group {
  padding-inline: 1rem !important;
  width: 100% !important;
}

.LB_StoreLocator .input-group {
  padding-inline: 1rem !important;
  border-bottom: 2px solid #002c90 !important;
  padding-block: 1rem !important;
  display: flex !important;
  align-items: center !important;
  gap: 2rem !important;
}

.LB_StoreLocator .input-group > .form-control {
  border: none !important;
  outline: 0 !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  font-size: 2rem !important;
  font-stretch: normal;
  font-style: normal;
  font-weight: 700;
  color: #002c90 !important;
}

.LB_StoreLocator .input-group > .form-control::placeholder {
  color: #002c90 !important;
  opacity: 0.8 !important;
  font-size: 2rem !important;
  font-stretch: normal;
  font-style: normal;
  font-weight: 700;
}

.LB_StoreLocator .input-group-addon.btn {
  display: none !important;
}

.LB_StoreLocator .glyphicon-search {
  color: #002c90 !important;
  font-size: 2rem !important;
}

.LB_StoreLocator .filters-button {
  background-color: transparent;
  border: 0;
}
