@import "../../../styles/variables/colors";
@import "../../../styles/mixins/textStyle.mixin";

.main {
  margin: 10rem 0;
  padding: 0 7%;
}

.tabContent {
  max-width: 90rem;
  width: 100%;
  margin: auto;
  margin-top: 10rem;
  @include textStyle($grey_text, 2rem, 500);
}
