@import "../../styles/variables/colors";
@import "../../styles/mixins/textStyle.mixin";

.main {
  display: flex;
  flex-wrap: wrap;
}

.imageSideFlex {
  display: flex;
  width: 100%;
}

.sideContent {
  width: 50%;
  display: flex;
  flex-direction: column;

  > div {
    width: 100% !important;
    flex: 1;
  }
}

@media screen and (max-width: 1024px) {
  .main {
    flex-direction: column;
  }

  .imageSideFlex {
    flex-direction: column;
  }

  .sideContent {
    width: 100%;
  }
}
