@import "../../styles/variables/colors";
@import "../../styles/mixins/textStyle.mixin";

.wrapper {
  border-radius: 10px;
  border: solid 1.5px $blue;
  padding: 2rem 1rem;
  background-color: transparent;
  @include textStyle($blue, 1.6rem);
  margin: 1rem;
  cursor: pointer;

  &.active {
    background-color: $blue;
    color: white;
  }
}
