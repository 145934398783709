.wrapper {
  width: 100%;
  margin: 0 auto;
}

.item {
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
}


.collapsed {
  max-height: 0;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

.title {
  font-weight: 400;
  font-size: 22px;
  cursor: pointer;
  color: #fff;
  padding: 1.5em 1.5em;
  border-bottom: solid 1px #fff;
  border-top: solid 1px #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &::after {
    content: "";
    border-bottom: 0.25rem solid;
    border-right: 0.25rem solid;
    display: inline-block;
    width: 0.75rem;
    height: 0.75rem;
    color: #fff;
    transform: rotate(45deg);
    transition: all 0.3s ease 0s;
  }

  &.open {
    color: #fff;
    &::after {
      content: "";
      border-bottom: 0.25rem solid;
      border-right: 0.25rem solid;
      display: inline-block;
      width: 0.75rem;
      height: 0.75rem;
      color: #fff;
      transform: rotate(225deg);
      transition: all 0.3s ease 0s;
    }
  }
}


.content {
  padding: 1em 1.5em;
}

