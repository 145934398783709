@import "../../../styles/variables/colors";
@import "../../../styles/mixins/textStyle.mixin";

h1 {
  background: rgba(0, 0, 0, 0.2);
}

.link {
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
  margin-left: 5px;
}

.main {
  display: flex;
}

.tabs {
  padding: 10rem 7% 0;
}

.tabTitle {
  @include textStyle($grey, 3.2rem, 700, normal, normal);
  margin: 7rem 0 3rem;
  text-align: center;
}

h2 {
  @include textStyle(#fff, 3.2rem, 700, normal, normal);
  margin-bottom: 3rem;
}

.carousel {
  margin-bottom: 7rem;
}

.text {
  opacity: 0.65;
  line-height: 2.6rem;
  p {
    margin-bottom: 2rem;
  }
}

.left {
  display: flex;
  flex-direction: column;
  width: 50%;
  .info {
    background: $blue;
    flex: 1 1;
    padding: 10rem 12.5rem;
  }

  .text {
    @include textStyle(#fff, 1.8rem, 100, normal, normal);
    margin-bottom: 5rem;
  }
}

.right {
  background: $darkgray_background;
  width: 50%;

  h2 {
    color: $grey;
  }

  .text {
    @include textStyle($grey, 1.8rem, 100, normal, normal);
    margin-bottom: 4rem;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  .image {
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.download {
  h2 {
    text-align: center;
    @include textStyle($blue, 3.2rem, 700, normal, normal);
  }
}

.row {
  display: flex;
  justify-content: space-between;
}

.files {
  display: flex;
  justify-content: space-between;
}

.column {
  &:nth-child(1) {
    margin-right: 3rem;
  }
}

.one-file {
  display: flex;
  align-items: center;
  cursor: pointer;
  line-height: 2.6rem;
  &:not(:last-child) {
    margin-bottom: 4rem;
  }

  span {
    padding-left: 3rem;
    @include textStyle(#fff, 1.8rem, 300, normal, normal);
    opacity: 0.65;
    flex: 1 1;
  }

  svg path {
    fill: #fff !important;
    opacity: 0.65;
  }
}

.note {
  @include textStyle($grey_text, 1.8rem, 300, normal, normal);
  line-height: 2.4rem;
  margin-bottom: 4rem;
  span {
    &:nth-child(1) {
      opacity: 1;
    }
    &:nth-child(2) {
      opacity: 0.65;
    }
  }
}
@media screen and (max-width: 1700px) {
  .info {
    padding: 5rem 7rem;
  }
}

@media screen and (max-width: 1600px) {
  .left {
    .info {
      padding: 3.5rem 4.5rem;
    }
  }
}

@media screen and (max-width: 1300px) {
  .right {
    width: 100%;
  }

  .left {
    width: 100%;
  }

  .main {
    flex-direction: column;

    > div {
      width: 100%;
    }
  }

  .image {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .one-file {
    margin-bottom: 2rem;
    span {
      padding-left: 2rem;
    }
    svg {
      width: 3rem;
    }
  }

  .left {
    .info {
      padding: 3.5rem 2.5rem;
    }
  }
}

@media screen and (max-width: 700px) {
  .one-file {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
}
