.wrapper {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (max-width: 1024px) {
  .main {
    padding-bottom: 0 !important;
  }
}

@media screen and (max-width: 1024px) {
  .main {
    padding-bottom: 2rem;
  }
}
