@import "../../styles/mixins/textStyle.mixin";
@import "../../styles/variables/colors";

.wrapper {
  width: fit-content;
  position: relative;
  display: flex;
  padding: 15px 0;

  .item:last-child {
    margin-right: 0;
  }
}

.line {
  position: absolute;
  right: 0;
  left: 0;
  height: 1.5px;
  background-color: #cccccc;
  bottom: 0;
  z-index: 1;
}

.container {
  overflow: hidden;
  overflow-x: auto;
}

.block {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 501px;
    left: -500px;
    height: 1.5px;
    background-color: #cccccc;
    bottom: 0;
    z-index: 1;
  }
}

.item {
  @include textStyle($grey_text, 1.7rem, 400, normal, normal);
  letter-spacing: 1.1px;
  margin-right: 8rem;
  cursor: pointer;
  transition: all 300ms ease;
  text-transform: capitalize;
  display: flex;
  align-items: flex-end;

  &:hover {
    color: $blue;
  }

  &.active {
    position: relative;
    color: $blue;

    &::before {
      content: "";
      position: absolute;
      bottom: -15px;
      right: -3px;
      left: 0;
      height: 2px;
      background-color: $blue;
      z-index: 3;
    }
  }
}

@media screen and (max-width: 550px) {
  .item {
    @include textStyle($grey_text, 1.8rem, bold, normal, normal);
    margin-right: 4rem;
  }

  .wrapper {
    overflow: hidden;
    overflow-x: auto;
    width: 100%;
  }
}

@media screen and (max-width: 430px) {
  .item {
    @include textStyle($grey_text, 1.5rem, bold, normal, normal);
    margin-right: 1.8rem;
  }
}
