.LB_LocationDetails {
  min-height: auto !important;
}

.LB_LocationDetails .container-fluid {
  padding-block: 2rem !important;
}

.LB_LocationDetails .LB_Title {
  padding-bottom: 3rem !important;
}

.LB_LocationDetails .header_title_container {
  padding: 0 !important;
  margin: 0 !important;
}

.LB_LocationDetails .header_title {
  display: none !important;
}

.LB_LocationDetails .header_buttons {
  display: flex !important;
  align-items: center !important;
}

.LB_LocationDetails .glyphicon {
  font-size: 2rem !important;
}

.LB_LocationDetails #LB_Details_Address .row {
  font-size: 1.3rem !important;
}

.LB_LocationDetails #LB_Details_Address h4 {
  font-size: 2.3rem !important;
}

.LB_LocationDetails div:has(> .glyphicon) {
  width: auto !important;
}

.LB_LocationDetails .opening-time-select {
  display: flex !important;
  align-items: flex-start !important;
}

.LB_LocationDetails .opening-time-select:has(.hidden) {
  align-items: center !important;
}

.LB_LocationDetails .opening-time-select .row {
  margin-block: 0 !important;
}

.LB_LocationDetails .opening-time-select .row:has(:not(.hidden)):not(:first-of-type) {
  padding-top: 1rem !important;
}

.LB_LocationDetails #LB_Details_description,
.LB_LocationDetails #LB_News_hr,
.LB_LocationDetails #LB_Media_hr,
.LB_LocationDetails hr {
  display: none !important;
}

.LB_LocationDetails .row:has(> #LB_Details_map) {
  background-color: #002c90 !important;
  color: white !important;
  min-height: 50vh !important;
  display: flex !important;
  flex-wrap: wrap !important;
}

.LB_LocationDetails .row:has(> #LB_Details_map) > div:first-of-type {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.LB_LocationDetails .row > * {
  width: auto !important;
}

.LB_LocationDetails #LB_Details_Address {
  padding-block: 0 !important;
}

.LB_LocationDetails div:has(> #LB_Details_Address) {
  min-height: 100% !important;
}

.LB_LocationDetails #LB_Details_map {
  padding: 0 !important;
  min-height: 100% !important;
  flex: 2;
}

.LB_LocationDetails #LB_MAPBOX {
  min-height: 100% !important;
}

.LB_LocationDetails .LB_MAP {
  height: 100% !important;
}

.LB_LocationDetails .nearby_location {
  padding: 2rem !important;
  box-shadow: 10px 10px 7px -8px #ededed;
  transition: transform 0.3s;
}

.LB_LocationDetails .nearby_location:hover {
  transform: scale(1.02);
}

.LB_LocationDetails .nearby_location > a {
  display: flex !important;
  flex-direction: column !important;
  gap: 1rem !important;
}

.LB_LocationDetails .nearby_location > a > h4 {
  color: #002c90 !important;
  font-weight: 600;
}

.LB_LocationDetails #LB_Nearby_locations {
  display: flex !important;
  flex-direction: column !important;
  max-width: 100% !important;
}

.LB_LocationDetails #LB_Nearby_locations > a {
  width: 100% !important;
  display: block !important;
  padding-block: 1.5rem !important;
  color: white !important;
  border-radius: 3rem !important;
  text-align: center !important;
  font-weight: 600 !important;
  font-size: 1.3rem !important;
  transition: background-color 0.3s !important;
  background-color: #0033a0 !important;
  margin-top: 1.5rem !important;
}

.LB_LocationDetails #LB_Nearby_locations > a:hover {
  background-color: #002c90 !important;
}

.LB_LocationDetails #LB_Nearby_locations > h4 {
  color: #002c90 !important;
  font-weight: 600 !important;
  font-size: 2.5rem !important;
  margin: 0 !important;
}

@media screen and (max-width: 768px) {
  .LB_LocationDetails .row:has(> #LB_Details_map) {
    flex-direction: column !important;
  }

  .LB_LocationDetails div:has(> #LB_Details_Address) {
    padding-block: 2rem !important;
  }
}
