@import "../../../styles/variables/colors";
@import "../../../styles/mixins/textStyle.mixin";

.wrapper {
  background: $darkgray_background;
  box-sizing: border-box;
  padding: 7% 10%;
  height: 100%;
}

.container {
  max-width: 560px;
  width: 100%;
}

.title {
  margin-bottom: 3.5rem;
}

.tabs-links {
  display: flex;
  border-bottom: 1px solid #d1d0d0;
  margin-bottom: 8rem;
  max-width: 202px;
}

.tab-select {
  margin-bottom: 50px;
}

.tabs-link {
  @include textStyle($grey_text, 1.8rem, 300, normal, normal);
  position: relative;
  opacity: 0.55;
  padding-bottom: 6px;
  transition: 0.4s ease;
  cursor: pointer;
  &:nth-child(1) {
    margin-right: 8rem;
  }
}

.active {
  opacity: 1;
  color: $blue;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -2px;
    height: 2px;
    background: $blue;
  }
}

.tab-select-wrapper {
  margin-bottom: 4rem;
}

.tab-radio-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6rem;
}

.tab-radio-label {
  margin-left: 20px;
}

.tab-radio-inner {
  @include textStyle($blue, 1.8rem, 300, normal, normal);
}

.tab-content-title {
  @include textStyle($blue, 8rem, 700, normal, normal);
  text-transform: uppercase;
  margin-bottom: 10px;
}

.price-text {
  @include textStyle($grey_text, 1.8rem, 300, normal, normal);
  opacity: 0.65;
  max-width: 300px;
  line-height: 24px;
}

.tab-bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.tabs-content-item {
  margin-top: 6rem;
  max-width: 400px;
  width: 100%;
}

.price {
  @include textStyle($grey_text, 1.8rem, 550, normal, normal);
}

.reset {
  @include textStyle($blue, 1.8rem, 400, normal, normal);
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

@media screen and (max-width: 1680px) {
  .title {
    margin-bottom: 2.5rem;
  }

  .tabs-content-item {
    margin-top: 3rem;
  }

  .tab-content-title {
    font-size: 5rem;
  }

  .tab-select-wrapper {
    margin-bottom: 3rem;
  }

  .tab-radio-wrapper {
    margin-bottom: 4rem;
  }
}

@media screen and (max-width: 1024px) {
  .wrapper {
    padding: 5% 4%;
  }

  .tab-select-wrapper {
    margin-bottom: 5rem;
  }

  .tab-content-title {
    margin-bottom: 2rem;
  }

  .container {
    width: calc(100% - 60px);
  }

  .price-text {
    margin-right: 20px;
  }
}

@media screen and (max-width: 500px) {
  .wrapper {
    padding: 5% 4% 7% 4%;
  }
}
