@mixin object-fit($fit: cover, $position: null) {
  -o-object-fit: $fit;
  object-fit: $fit;
  @if $position {
    -o-object-position: $position;
    object-position: $position;
    font-family: "object-fit: #{$fit}; object-position: #{$position}";
  } @else {
    font-family: "object-fit: #{$fit}";
  }
}
