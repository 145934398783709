.play-btn {
  width: 250px;
  height: 250px;
  position: relative;
  background-color: grey;
  border-radius: 100%;
  margin: 0 auto;
  cursor: pointer;

  &::before {
    position: absolute;
    content: "\A";
    border-style: solid;
    border-width: 70px 0 70px 90px;
    border-color: transparent transparent transparent #dddddd;
    left: 0;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
  }
}

.small {
  width: 80px;
  height: 80px;

  &::before {
    border-width: 20px 0 20px 30px;
    border-color: transparent transparent transparent #dddddd;
  }
}
