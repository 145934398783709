@import "../../../styles/variables/colors";
@import "../../../styles/mixins/textStyle.mixin";

.main {
  display: flex;
  flex-direction: column;
}

.bodySections {
  display: flex;
  flex-direction: column;
}

.bodySectionsFlex {
  display: flex;
}

.overviewSection {
  width: 50%;
}

.imageSectionContext {
  display: flex;
  flex-direction: column;
  width: 50%;

  > div {
    flex: 1;
  }
}

@media screen and (max-width: 1024px) {
  .bodySectionsFlex {
    flex-direction: column;
  }

  .bodySections {
    flex-direction: column;
  }

  .overviewSection {
    width: 100%;
  }

  .imageSectionContext {
    width: 100%;
  }
}
