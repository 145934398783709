@import "../../styles/variables/colors";
@import "../../styles/mixins/textStyle.mixin";

.wrapper {
  position: relative;
  background-color: $blue;
  flex: 1;
  padding: 0 0 0 10%;
  z-index: 100;
}

.filterHeight {
  // margin-left: 10px;
  padding-left: 15px;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
}

.title {
  @include textStyle(white, 4rem, bold);
  margin-bottom: 5rem;
}

.filtersItemWrapper {
  margin-bottom: 5rem;
}

.filterItemTitle {
  @include textStyle(white, 1.8rem);
}

.filterItemGroup {
  margin-bottom: 6rem;

  > * {
    margin: 0 0 2.5rem !important;
  }
}
