@import "../../../styles/mixins/textStyle.mixin";

:global .fixed-search-enter {
  margin-top: -1000px;
}
:global .fixed-search-enter-active {
  margin-top: 0;
  transition: margin 500ms;
}
:global .fixed-search-exit {
  margin-top: 0;
}
:global .fixed-search-exit-active {
  margin-top: -1000px;
  transition: margin 500ms;
}

.search {
  max-width: 62rem;
  width: 100%;
  margin: 0 auto;
  padding: 0 1.5rem;
  box-sizing: border-box;
}

.contacts-container {
  padding: 1.5rem 0;
  display: flex;
  flex-wrap: wrap;

  > div {
    margin: 20px;
    width: calc((100% - 125px) / 3);
  }
}

.view-all-button {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 0 5rem 0;
}

.direction {
  @include textStyle($blue);
  color: $blue !important;
  letter-spacing: 1.1px;
  display: flex;
}

.card-container {
  width: 100%;
  display: flex;
}

.card-column {
  width: 50%;
}

@media only screen and (max-width: 1100px) {
  .contacts-container {
    flex-direction: column;
    align-items: center;
    > div {
      width: 70rem;
      margin: 0 0 40px 0;
      &:last-child {
        margin: 0;
      }
    }
  }

  .search {
    margin-bottom: 2rem;
  }
}

@media only screen and (max-width: 1024px) {
  .view-all-button {
    margin: 0rem 0 6rem;
  }

  .search {
    max-width: 47rem;
  }
}

@media only screen and (max-width: 800px) {
  .contacts-container > div {
    width: 55rem;
  }
}

@media only screen and (max-width: 650px) {
  .contacts-container > div {
    width: 100%;
  }
}

@media only screen and (max-width: 640px) {
  .search {
    margin-bottom: 2rem;
  }

  .search {
    padding: 0 2.5rem;
  }
}

@media only screen and (max-width: 600px) {
  .search {
    max-width: 40rem;
  }
}

@media only screen and (max-width: 400px) {
  .search {
    padding: 0 4rem;
  }
}
