@import "../../styles/variables/colors";

.wrapper {
  display: flex;
  align-items: center;
  max-width: 400px;
  width: 100%;
}

.input {
  color: white;
  border-bottom: 2px solid white;
  width: 100%;
  font-size: 1.8rem;
  font-family: "Univers LT Std";
  font-weight: 300;
  padding: 5px 1px;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  outline: 0;
  background-color: transparent;
  transition: 0.4s ease;

  &::placeholder {
    color: white;
  }
}

.icon {
  margin-right: 1.5rem;
}

@media screen and (max-width: 1024px) {
  .icon {
    margin-right: 1rem;
    svg {
      width: 20px;
    }
  }

  .input {
    opacity: 1 !important;
    font-size: 1.6rem !important;
  }
}
