@import "../../../styles/variables/colors";
@import "../../../styles/mixins/textStyle.mixin";

.radio {
  margin: 0.5rem;
  @include textStyle($blue, 1.6rem, 300, normal, normal);
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    + .radio-label {
      font-size: 1.6rem;
      position: relative;
      padding-left: 30px;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 500px) {
  .radio {
    input[type="radio"] {
      + .radio-label {
        font-size: 1.4rem;

        &:after {
          top: 4px !important;
        }
      }
    }
  }
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #fff;
  font-size: 1.6rem;
  cursor: pointer;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
}

.checkmark {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border: 2px solid #fff;
  border-radius: 50%;
  &:after {
    content: "";
    position: absolute;
    display: none;
    top: 3px;
    left: 3px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }
}
