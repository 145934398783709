@import "../../styles/variables/colors";
@import "../../styles/mixins/textStyle.mixin";

.wrapper {
  max-width: 36rem;
  width: 100%;
  height: 100%;
  max-height: 50rem;
  box-sizing: border-box;
  box-shadow: 10px 20px 70px 1px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  padding: 2.5rem;
  @include textStyle($blue, 1.8rem, 400, normal, normal);
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.img-wrapper {
  margin-bottom: 40px;
  max-height: 300px;
}

.name {
  line-height: 27px;
}

.img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  max-height: 30rem;
}
