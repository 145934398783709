@import "../../styles/variables/colors";
@import "../../styles/mixins/textStyle.mixin";

.title {
  margin: 10rem 0 5rem;
  @include textStyle($grey, 3.8rem, 700, normal, normal);
}

.products-section {
  display: flex;
}

.productsWrapper {
  width: 100%;
  margin: 10rem 5rem;
}

.filters {
  max-width: 400px;
  min-width: 320px;
  width: 100%;
  display: flex;
  top: 0;
}

.search {
  max-width: 500px;
  width: 100%;
  margin-bottom: 7.5rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: center;
}

.load-more {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10rem;
}

.products {
  display: flex;
  flex-wrap: wrap;
}

.product {
  width: 33%;
  padding: 1.5rem;

  @media screen and (max-width: 1200px) {
    width: 50%;
  }

  @media screen and (max-width: 768px) {
    width: 50%;
  }

  @media screen and (max-width: 550px) {
    padding: 1.5rem 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.search-icon {
  margin: 0 2rem;
  cursor: pointer;
}

@media screen and (max-width: 991px) {
  .productsWrapper {
    margin: 0;
    margin-bottom: 7rem;
  }

  .search {
    align-items: flex-end;
    margin-bottom: 0;
    padding: 3.5rem 0;
    margin: 0 auto;
    max-width: 300px;
    width: unset;
  }

  .productsContainer {
    position: relative;
  }

  .products {
    margin: 0 3rem;
  }

  .load-more {
    width: unset;
    margin: 7rem 3rem;
  }
}

.mobileFilters {
  display: none;
  position: absolute;
  top: 0;
  width: 100%;

  &.showFilters {
    display: block;
  }
}
